<template>
  <b-card
      class="statementproxyverification-edit-wrapper"
  >
    <!-- form -->
    <b-form id="statementproxyverificationForm" class="edit-form mt-2">
      <b-row>
        <!--基本信息-->
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>
        <!--店铺团队-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="店铺团队"
              label-for="store_name"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="store_name"
                size="sm"
                readonly
                v-model="statementproxyverification.store_name"
            />
          </b-form-group>
        </b-col>
        <!--核销人-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="核销人"
              label-for="creator"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="creator"
                size="sm"
                readonly
                v-model="creatorName"
            />
          </b-form-group>
        </b-col>
        <!--核销编号-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="核销编号"
              label-for="ve_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="ve_no"
                size="sm"
                readonly
                v-model="statementproxyverification.ve_no"
            />
          </b-form-group>
        </b-col>
        <!--流水清单-->
        <b-col md="12">
          <div class="inner-card-title">流水清单</div>
        </b-col>
        <b-col md="8">
          <finance-resource-list :ids=this.ids.toString() :ve_id=this.id :status="this.statementproxyverification.status" ref="financeRef"></finance-resource-list>
        </b-col>
        <!--代收清单-->
        <b-col md="12">
          <div class="inner-card-title">代收清单</div>
        </b-col>
        <b-col md="12">
          <b-card class="mb-0">
            <!--添加信息-->
            <div class="mb-1">
              <b-button
                  variant="outline-success"
                  @click="showModal"
                  class="mr-1"
              >
                <feather-icon
                    icon="PlusIcon"
                />
                <span class="text-nowrap">添加单据</span>
              </b-button>
            <!--清空单据-->
              <b-button
                  variant="outline-danger"
                  @click="emptyProxy"
                  v-if="this.statementproxyverification.status != 2 && this.statementproxyverification.status != 3"
              >
                <feather-icon
                    icon="Trash2Icon"
                />
                <span class="text-nowrap">清空单据</span>
              </b-button>
            </div>

            <b-table
                ref="refListTable"
                class="position-relative"
                :items="proxyList"
                responsive
                hover
                small
                :fields="proxyListColumns"
                primary-key="id"
                show-empty
                empty-text="未找到记录"
                sticky-header
            >
              <template #cell(pro_id)="data">
                #{{ data.item.pro_id }}
              </template>

              <template #cell(reveive_date)="data">
                {{ toDate(data.item.reveive_date) }}
              </template>

              <template #cell(creator)="data">
                {{ getCodeLabel('user', data.item.creator) }}
              </template>

              <template #cell(status)="data">
                {{ getCodeLabel('pro_status', data.item.status) }}
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-secondary"
                    @click="removeItem(data.item)" size="sm">
                  删除
                </b-button>
              </template>

              <template v-slot:custom-foot="data">
                <b-tr>
                  <b-td :colspan="4" variant="primary">
                    <span><strong>合计</strong></span>
                  </b-td>
                  <b-td :colspan="1" variant="primary">
                    <span><strong>{{ proxy_amount }}</strong></span>
                  </b-td>
                  <b-td :colspan="1" variant="primary">
                    <span><strong>{{ proxy_amount_verification }}</strong></span>
                  </b-td>
                  <b-td :colspan="3" variant="primary">
                    <span><strong></strong></span>
                  </b-td>
                </b-tr>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <!--其他信息-->
        <b-col md="12">
          <div class="inner-card-title">其他信息</div>
        </b-col>
        <!--备注-->
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="备注"
              label-for="memo"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="memo"
                size="sm"
                v-model="statementproxyverification.memo"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
        <!--相关附件-->
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="相关附件"
              label-for="attachments"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="statementproxyverification.loaded" :theme="'files'"
                               attachment_id="attachment"
                               :id="statementproxyverification.attachments"
                               object_type="statementproxyverification"
                               :object_id="statementproxyverification.load_id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="outline-success"
              class="mr-1"
              @click="audit(id,3)"
              v-if="this.statementproxyverification.status == 2 && [11,12,14,27].includes(user.role_id)"
          >
            审核通过
          </b-button>
          <b-button
              variant="outline-danger"
              class="mr-1"
              @click="audit(id,1)"
              v-if="this.statementproxyverification.status == 2 && [11,12,14,27].includes(user.role_id)"
          >
            驳回
          </b-button>
          <b-button
              variant="outline-primary"
              class="mr-1"
              @click="save(1)"
              v-if="this.statementproxyverification.status != 2 && this.statementproxyverification.status != 3"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              class="mr-1"
              @click="cancel"
          >
            取消
          </b-button>
          <b-button
              variant="outline-success"
              @click="save(2)"
              v-if="this.statementproxyverification.status != 2 && this.statementproxyverification.status != 3"
          >
            提交
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
    <!--添加单据模态框-->
    <b-modal
        id="selectModal"
        ok-title="确认"
        cancel-title="取消"
        @ok="onSelectProxy"
        centered
        size="xl"
        title="添加单据"
    >
      <statement-proxy-list ref="proxyList" :store_id="this.statementproxyverification.store_id"></statement-proxy-list>
    </b-modal>
    <!--loading-->
    <b-modal
        id="loadingModal"
        centered
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        ref="loadingModal"
    >
      <p class="my-4">处理中，请勿关闭浏览器!</p>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import statementproxyverificationStore from './statementproxyverificationStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty,
  isNumber
} from '@core/utils/filter'
import {getUserData} from "@/auth/utils";
import financeResourceList from "@/views/apps/statementproxyverification/financeresource/FinanceResourceList";
import statementProxyList from "@/views/apps/statementproxyverification/proxy/StatementProxyList";
import Ripple from "vue-ripple-directive";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import axios from "@/libs/axios";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    financeResourceList,
    statementProxyList,
    AttachmentUpload,
  },
  data() {
    return {
      user:{},
      id: 0,
      ids: [],
      statementproxyverification: ref({}),
      creatorName: '',
      proxyList: [],
      proxyListColumns: [
        {key: 'pro_id', label: 'ID',},
        {key: 'receive_no', label: '收款编号',},
        {key: 'channel_name', label: '终端客户'},
        {key: 'reveive_date', label: '收款日期'},
        {key: 'amount', label: '现金代收金额'},
        {key: 'amount_verification', label: '核销金额'},
        {key: 'creator', label: '创建人'},
        {key: 'status', label: '核销状态'},
        {key: 'actions', label: '操作'},
      ],
      proxy_amount:0,
      proxy_amount_verification:0,
    }
  },
  directives: {
    Ripple,
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('statementproxyverification')) store.registerModule('statementproxyverification', statementproxyverificationStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementproxyverification')) store.unregisterModule('statementproxyverification')
    })

    const edit = function () {
      store.dispatch('statementproxyverification/edit', {id: this.id}).then(res => {
        this.statementproxyverification = res.data.data
        if (isEmpty(this.statementproxyverification.store_id)) {
          this.statementproxyverification.store_id = this.$route.query.store_id
        }
        if (isEmpty(this.statementproxyverification.store_name)) {
          this.statementproxyverification.store_name = this.$route.query.store_name
        }
      })

      if(this.id != 0){
        axios.post('/api/statementproxy/getByVeId',{ve_id:this.id}).then(res=>{
          this.proxyList = res.data.data.list
          this.calAmount()
        })
      }
    }

    const cancel = function () {
      this.$router.push({name: 'apps-statementproxyverification-list'})
    }

    const save = function (status) {
      let flag = true
      this.$refs.financeRef.financeList.forEach(e=>{
        if(isEmpty(e.ext.amount_verification)){
          toast.error('ID#'+e.finance_id+'的流水清单 核销金额不能为空')
          flag = false
          return false
        }
        if(e.ext.can_receive_amount >0  && e.ext.can_receive_amount < isNumber(e.ext.amount_verification)){
          toast.error('ID#'+e.finance_id+'的流水清单 核销金额需小于待核销金额')
          flag = false
        }
        if(e.ext.can_receive_amount <0  && e.ext.can_receive_amount > isNumber(e.ext.amount_verification)){
          toast.error('ID#'+e.finance_id+'的流水清单 核销金额需大于待核销金额')
          flag = false
        }
      })
      if(this.$refs.financeRef.total_amount_verification != this.proxy_amount_verification){
        toast.error('流水清单核销总金额需等于代收清单核销总金额')
        flag = false
      }
      if(this.proxyList.length < 1){
        toast.error('请选择代收清单')
        flag = false
      }
      if(flag){
        this.statementproxyverification.status = status
        let itemList = []
        this.$refs.financeRef.financeList.forEach(e=>{
          let item = {}
          item.type = 1
          item.item_id = e.finance_id
          item.amount_verification = e.ext.amount_verification
          itemList.push(item)
        })
        this.proxyList.forEach(e=>{
          let item = {}
          item.type = 2
          item.item_id = e.pro_id
          item.amount_verification = e.amount_verification
          itemList.push(item)
        })
        this.statementproxyverification.itemList = itemList
        this.statementproxyverification.amount_verification = this.proxy_amount_verification
        console.log(this.statementproxyverification)
        this.$refs['loadingModal'].show()
        store.dispatch('statementproxyverification/save', this.statementproxyverification).then(res => {
          if(res.data.code == 0){
            toast.success('数据已保存!')
            this.$router.push({name: 'apps-statementproxyverification-list'})
          }else {
            this.statementproxyverification.status = 1
            toast.error(res.data.data)
          }
          this.$refs['loadingModal'].hide()
        })
      }

    }

    const showModal = function () {
      this.$bvModal.show('selectModal')
    }

    const onSelectProxy = function () {
      let selectArr = this.$refs.proxyList.getSelected()
      console.log(selectArr)
      selectArr.forEach(arr => {
        let flag = false
        this.proxyList.forEach(pro => {
            if(arr.pro_id == pro.pro_id){
              flag = true
            }
        })
        if(!flag) {
          this.proxyList.push(arr)
        }
      })
      this.$forceUpdate()
      this.calAmount()
    }

    const removeItem = function (param) {
      if(this.statementproxyverification.status == 2 || this.statementproxyverification.status == 3){
        toast.error('非草稿状态下不能删除！')
        return
      }
      this.$swal({
        title: '确定删除吗',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          axios.post('/api/statementproxy/removeItem', {id:param.pro_id}).then(res=>{})
          for (let i = 0; i < this.proxyList.length; i++) {
            if (this.proxyList[i].pro_id == param.pro_id) {
              this.proxyList.splice(i, 1)
            }
          }
          this.calAmount()
        }
      })
    }

    const emptyProxy = function () {
      this.$swal({
        title: '确定清空单据吗',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.proxyList = []
          this.calAmount()
        }
      })
    }

    const onUploaded = function (id, attachment, result) {
      this.statementproxyverification.attachments = result
    }

    const calAmount = function () {
      let amount1 = 0
      let amount2 = 0;
      this.proxyList.forEach(e => {
        amount1 = Number(amount1) + Number(e.amount)
        amount2 = Number(amount2) + Number(isNumber(e.amount_verification))
      })

      this.proxy_amount = amount1.toFixed(2)
      this.proxy_amount_verification = amount2.toFixed(2)
      this.$forceUpdate()
    }

    const audit = function (id, status) {
      this.$refs['loadingModal'].show()
      axios.post('/api/statementproxyverification/audit', {id: id, status: status}).then(res => {
        if (res.data.code == 0) {
          toast.success('已更新')
          this.$router.push({name: 'apps-statementproxyverification-list'})
        }else {
          toast.error(res.data.data)
        }
        this.$refs['loadingModal'].hide()
      })
    }

    return {
      audit,
      edit,
      cancel,
      save,
      showModal,
      onSelectProxy,
      removeItem,
      emptyProxy,
      onUploaded,
      calAmount,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
  created() {
    const userData = getUserData()
    this.creatorName = userData.full_name
    this.id = Number(this.$route.query.id) || 0
    this.ids = this.$route.query.ids
    this.edit()
    this.user = userData
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
