<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        class="mb-0"
    >

      <b-table
          sticky-header
          ref="refListTable"
          class="position-relative"
          :items="financeList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(finance_id)="data">
          #{{ data.item.finance_id }}
        </template>

        <template #cell(can_receive_amount)="data">
          {{ data.item.ext.can_receive_amount }}
        </template>

        <template #cell(amount_verification)="data">
          <div style="width: 100px">
            <b-form-input
                @blur="fixNumber(data.item)"
                size="sm"
                type="number"
                v-model="data.item.ext.amount_verification"
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-secondary"
              @click="removeItem(data.item)" size="sm">
            删除
          </b-button>
        </template>

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="2" variant="primary">
              <span><strong>合计</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ total_amount }}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong>{{ total_amount_verification }}</strong></span>
            </b-td>
            <b-td :colspan="1" variant="primary">
              <span><strong></strong></span>
            </b-td>
          </b-tr>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty,
  addNumber, isNumber,
} from '@core/utils/filter'
import financeresourceUseList from './financeresourceUseList'
import axios from "@/libs/axios";
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      financeList: [],
      total_amount: 0,//待核销金额
      total_amount_verification: 0,//核销金额
    }
  },
  directives: {
    Ripple,
  },
  props: {
    ids: {
      type: String,
      default: '0',
    },
    ve_id:{
      type:Number,
      default: 0,
    },
    status:{
      type:Number,
      default:0,
    }
  },
  setup(props) {
    const toast = useToast()
    const initFinanceTable = function () {
      axios.post('/api/financeresource/searchByIds', {ids: props.ids,ve_id:props.ve_id}).then(res => {
        this.financeList = res.data.data.list
        this.calAmount()
      })
    }

    const fixNumber = function (param) {
      this.financeList.forEach(e => {
        if(param.finance_id == e.finance_id){
          if (!isEmpty(param)) {
            e.amount_verification = Number(e.amount_verification).toFixed(2)
          } else {
            e.amount_verification = ''
          }
          this.$forceUpdate()
        }
      })
      this.calAmount()
    }

    const calAmount = function () {
      let amount1 = 0
      let amount2 = 0;
      this.financeList.forEach(e => {
        amount1 = Number(amount1) + Number(e.ext.can_receive_amount)
        amount2 = Number(amount2) + Number(isNumber(e.ext.amount_verification))
      })

      this.total_amount = amount1.toFixed(2)
      this.total_amount_verification = amount2.toFixed(2)
      this.$forceUpdate()
    }

    const removeItem = function (param) {
      if(props.status == 2 || props.status == 3){
        toast.error('非草稿状态下不能删除！')
        return
      }
      this.$swal({
        title: '确定删除吗',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          axios.post('/api/financeresource/removeItem', {id:param.entity_id,ve_id:props.ve_id}).then(res=>{})
          for (let i = 0; i < this.financeList.length; i++) {
            console.log(this.financeList[i].entity_id, param.entity_id)
            if (this.financeList[i].entity_id == param.entity_id) {
              this.financeList.splice(i, 1)
            }
          }
          this.calAmount()
        }
      })

    }

    const {
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      financeIds,

      // UI
    } = financeresourceUseList(props)

    return {
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      financeIds,
      fixNumber,
      calAmount,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      addNumber,
      removeItem,
      // UI
      toTime,
      toDate,
      initFinanceTable,
    }
  },
  created() {
    this.initFinanceTable()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
