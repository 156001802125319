<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- 高级搜索 -->
        <div>
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--收款编号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款编号"
                    label-for="receive_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="receive_no"
                      size="sm"
                      v-model="condition.receive_no"
                      placeholder="请输入收款编号"
                  />
                </b-form-group>
              </b-col>
              <!--终端客户-->
              <b-col md="3">
                <modal-select
                    label="终端客户"
                    type="input"
                    v-on:change="fromChildren($event,['channel_name','channel_id'],condition)"
                    :params="['channel_name','channel_id']"
                    modalName="终端客户"
                    placeholder="点击选择终端客户"
                    v-model="condition.channel_name"
                >
                </modal-select>
              </b-col>
              <!--收款日期-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款日期"
                    label-for="reveive_date"
                    label-size="sm"
                >
                  <flat-pickr
                      v-model="condition.reveive_date"
                      class="form-control"
                      :config="rangeConfig"
                      placeholder="请选择收款日期"
                  />
                </b-form-group>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                      cols="12"
                      md="6"
                      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>显示</label>
                <v-select
                        v-model="limit"
                        :options="limitOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50"
                />
                <label>条</label>
              </b-col>
              <b-col
                  cols="12"
                  md="6"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
        v-model="table"
      >
        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>
        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>

        <template #cell(reveive_date)="data">
            {{toDate(data.item.reveive_date)}}
        </template>

        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}}
        </template>

        <template #cell(status)="data">
          {{getCodeLabel('pro_status',data.item.status)}}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import statementproxyUseList from './statementproxyUseList'
import Ripple from "vue-ripple-directive";
import ModalSelect from "@/views/components/modal/ModalSelect";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalSelect,
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value)=>{
          tableIdArray.push(value.pro_id)
        })
        this.checked.forEach((value)=>{
          if (tableIdArray.indexOf(Number(value.pro_id))>-1){
            show+=1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0){
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
    table:{
      handler(newVal, oldVal){
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value)=>{
          tableIdArray.push(value.pro_id)
        })
        this.checked.forEach((value)=>{
          if (tableIdArray.indexOf(Number(value.pro_id))>-1){
            show+=1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        }else if (show === 0){
          this.indeterminate = false
          this.allchecked = false
        }else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },

  },
  methods: {
    getSelected: function () {
      return this.checked
    },
  },
  data() {
    return {
      rangeConfig: {
        mode: 'range',
        maxDate: 'today',
      },
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      checked: [],
    }
  },
  props: {
    store_id: {
      type: Number,
      default:0
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData();
    }

    const fromChildren = function (params, modal, item) {
      for (let i = 0; i < modal.length; i++) {
        item[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    // 点击全选按钮
    const toggleAll = function (checked) {
      let checkedProIds = [];
      this.checked.forEach(item =>{
        checkedProIds.push(Number(item.pro_id))
      })
      this.table.forEach(element => {
        if (checked) {
          if(checkedProIds.indexOf(element.pro_id) === -1){
            this.checked.push(element);
          }
        } else {
          //取消勾选
          for (let i = 0; i < this.checked.length; i++) {
            //在已勾选数组中找出要取消勾选的
            if (this.checked[i].pro_id == element.pro_id) {
              this.checked.splice(i, 1)
            }
          }
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()
    }
    // 复选框点击选中与取消
    const checkboxClick = function (item) {
      if (this.isCheckedObj['index' + item.id]) {
        //勾选
        this.checked.push(item)
      } else {
        //取消勾选
        for (let i = 0; i < this.checked.length; i++) {
          //在已勾选数组中找出要取消勾选的
          if (this.checked[i].pro_id == item.pro_id) {
            this.checked.splice(i, 1)
          }
        }
      }
      this.$forceUpdate()
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = statementproxyUseList(props)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      searchByCondition,
      resetCondition,
      condition,
      fromChildren,
      toggleAll,
      checkboxClick,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
